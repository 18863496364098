import { isManualChargeDetailEntity } from "domain/entity/ManualCharge/ManualChargeDetailEntity";
import { cntrTspStatusDropdownOption, emptyIndDropdownOption, uomDropdownOption, vatPaymentReferenceDateDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { ManualChargeConstant, manChgDtlRequiredFieldList } from "presentation/constant/ManualCharge/ManualChargeConstant";
import { useManualChargeDetailCntrVM } from "presentation/hook/ManualCharge/useManualChargeDetailCntrVM";
import { useManualChargeDetailVesselVM } from "presentation/hook/ManualCharge/useManualChargeDetailVesselVM";
import { useManualChargeDetailVM } from "presentation/hook/ManualCharge/useManualChargeDetailVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useManualChargeDetailTracked } from "presentation/store/ManualCharge/ManualChargeDetailProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import NumberInputComponent from "presentation/view/components/NumberInputComponent";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FieldType, HPHButton, HPHInputTextarea, IFieldValue, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import ManualChargeVesselComp from "../../ArrivalAndDepaVessel/ManualChargeVesselComp";
import ManualChargeCntrComp from "../../CntrDetail/ManualChargeCntrComp";

export const ManualChargeDetailFormPanel = () => {
    const [manualChargeDetailState] = useManualChargeDetailTracked();
    const manualChargeDetailVM = useManualChargeDetailVM();
    const manualChargeDetailCntrVM = useManualChargeDetailCntrVM();
    const messageBarVM = useMessageBarVM();
    const [isLoading, setIsLoading] = useState(false);
    const uploadRef = useRef<any>();
    const { isAdd, isRead, isEditable, isSaveClicked, allFormState, editingHeader, currentSelectedData, isAdjustmentRead } = manualChargeDetailState.viewState;
    const currentManChgHdr = editingHeader;
    const currentManChgDtl = currentSelectedData;

    const dynamicOptions = manualChargeDetailState.dynamicOptions;

    const HDR_CONST = ManualChargeConstant.Header;
    const DTL_CONST = ManualChargeConstant.Detail;
    const manualChargeDetailVesselVM = useManualChargeDetailVesselVM();

    useEffect(() => {
        if (manualChargeDetailState.viewState.isAdd && manualChargeDetailState.viewState.isShowDetailInfo) {
            manualChargeDetailVM.onInitDefaultValue(false);
        }

    }, [manualChargeDetailState.viewState.isAdd, manualChargeDetailState.viewState.isShowDetailInfo, manualChargeDetailVM]);

    const memoTariffCodeOptions = useMemo(() => {
        return currentManChgDtl.tariffType
            ? manualChargeDetailState.dynamicOptions.tariffCodeDropdownOptions[currentManChgDtl.tariffType]
            : [];
    }, [currentManChgDtl.tariffType, manualChargeDetailState.dynamicOptions.tariffCodeDropdownOptions]);

    const memoTaxTariffCodeOptions = useMemo(() => {
        return currentManChgDtl.vatTarType
            ? manualChargeDetailState.dynamicOptions.taxTariffCodeDropdownOptions[currentManChgDtl.vatTarType]
            : [];
    }, [currentManChgDtl.vatTarType, manualChargeDetailState.dynamicOptions.taxTariffCodeDropdownOptions]);

    useEffect(() => {
        const refereshTaxInfo = async () => {
            manualChargeDetailVM.onRefreshDefaultTarCode(currentManChgDtl.vatOneOffTaxCode, dynamicOptions.taxTariffTypeDropdownOptions,
                dynamicOptions.allTariffCodeeDropdownOptions, dynamicOptions.articleStatementCodeDropdownOptions
            );
        }
        refereshTaxInfo();
    }, [currentManChgDtl.vatOneOffTaxCode, dynamicOptions.allTariffCodeeDropdownOptions, dynamicOptions.articleStatementCodeDropdownOptions, dynamicOptions.taxTariffCodeDropdownOptions, dynamicOptions.taxTariffTypeDropdownOptions, manualChargeDetailState.dynamicOptions.articleStatementCodeDropdownOptions, manualChargeDetailState.dynamicOptions.taxTariffCodeDropdownOptions, manualChargeDetailState.dynamicOptions.taxTariffTypeDropdownOptions, manualChargeDetailVM])


    useEffect(() => {
        const refreshTariffDesc = async () => {
            manualChargeDetailVM.onTariffCodeItemChanged(currentManChgDtl.tariffType, currentManChgDtl.tariffCode, 'tariffCodeDesc');
        }
        refreshTariffDesc();
    }, [currentManChgDtl.tariffCode, currentManChgDtl.tariffType, manualChargeDetailVM])


    useEffect(() => {
        const refreshVatTariffDesc = async () => {
            manualChargeDetailVM.onTariffCodeItemChanged(currentManChgDtl.vatTarType, currentManChgDtl.vatTarCode, 'vatTariffCodeDesc');
        }
        refreshVatTariffDesc();
    }, [currentManChgDtl.vatTarCode, currentManChgDtl.vatTarType, manualChargeDetailVM])


    const handleCancelClick = () => {
        manualChargeDetailVM.onCancelDetail();
    }

    const handleSaveClick = useCallback(() => {
        setIsLoading(true);
        manualChargeDetailVM.onSaveClicked();
        manualChargeDetailVM.onSaveDetail(manualChargeDetailState.manualChargeDetails,currentManChgDtl, isAdd).then((res) => {
            if (res && isManualChargeDetailEntity(res)) {
                setIsLoading(false);
                manualChargeDetailVesselVM.initSearch(currentManChgDtl.cntrList);
            } else {
                setIsLoading(false);
                if (!currentManChgDtl.manualChargeHdrId) {
                    manualChargeDetailVesselVM.initSearch(currentManChgDtl.cntrList);
                }
                if (res && res["mandatoryCheckFail"]) {
                    messageBarVM.showError(res["mandatoryCheckFail"]?.toString());
                }
            }
        }).catch((error) => {
            setIsLoading(false);
            messageBarVM.showError(error.message);
        })

    }, [currentManChgDtl,manualChargeDetailState.manualChargeDetails, isAdd, manualChargeDetailVM, messageBarVM, manualChargeDetailVesselVM])

    const handleGetShift = async () => {
        if (currentManChgHdr.opsDate) {
            await manualChargeDetailVM.onGetShift(currentManChgHdr.opsDate).then((data) => {
                if (!data) {
                    messageBarVM.showError('The shift code is not found by ops date.');
                }
            }).catch((error) => {
                setIsLoading(false);
                messageBarVM.showError(error.message);
            })
        } else {
            messageBarVM.showError('Please input the Ops Date.');
        }
    }

    const handleCntrDetail = () => {
        manualChargeDetailCntrVM.onShowPanel(/*(manualChargeDetailState.cntrNoStr??*/currentManChgDtl?.cntrList as string);
    }

    const handleCntrTextAreaChange = useCallback((event: any) => {
        manualChargeDetailVM.onCntrTextAreaChange(event, "cntrList");
    }, [manualChargeDetailVM])

    const handleFileChange = async (event: any) => {
        const files = event.target?.files;
        const file = files[0];

        if (!file) {
            return;
        }
        if (file.type !== 'text/plain') {
            messageBarVM.showError('Please upload a .txt file');
            return
        }

        await manualChargeDetailVM.uploadCntr(currentManChgDtl?.cntrList ?? '', file);
        uploadRef.current.value = null;

    };

    const handleUploadCntr = (event: any) => {
        uploadRef.current?.click();
    }

    const handleGetUom = () => {
        if (!currentManChgHdr.opsDate) {
            messageBarVM.showError('Please input the Ops Date.');
            return;
        }

        if (!currentManChgDtl.tariffType || !currentManChgDtl.tariffCode) {
            messageBarVM.showError('Please input the Tariff Code.');
            return;
        }

        manualChargeDetailVM.onGetUom(currentManChgDtl.chargeOnCompany,
            currentManChgHdr.opsDate,
            currentManChgDtl.tariffType,
            currentManChgDtl.tariffCode,
            currentManChgDtl.fromDate == null ? currentManChgHdr.opsDate : currentManChgDtl.fromDate,
            currentManChgDtl.toDate == null ? currentManChgHdr.opsDate : currentManChgDtl.toDate,
            currentManChgHdr.operatingTml
        ).then((data) => {
            if (data && data["getUOMFail"]) {
                const err: string = data["getUOMFail"]?.toString() ?? "";
                messageBarVM.showError(err.replace("Error:", ""));
            }
        })
    }

    const onNumberFieldChange = useCallback((e: any, fieldName: string, fieldValue: any) => {
        manualChargeDetailVM.onFieldChange(fieldName, fieldValue);
    }, [manualChargeDetailVM]);

    const memoState = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.dtlState || ''}
                fieldValue={currentManChgDtl?.dtlState}
                fieldLabel={HDR_CONST.STATE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'dtlState'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [HDR_CONST.STATE, currentManChgDtl?.dtlState, isSaveClicked])

    const memoTarType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!isAdd}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.tariffType || ''}
                fieldValue={currentManChgDtl?.tariffType}
                fieldLabel={DTL_CONST.TAR_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffType'}
                options={manualChargeDetailState.dynamicOptions.tariffTypeDropdownOptions}
                maxLength={60}
                requiredFieldList={manChgDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    manualChargeDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [DTL_CONST.TAR_TYPE, allFormState, currentManChgDtl?.tariffType, isAdd, isSaveClicked, manualChargeDetailState.dynamicOptions.tariffTypeDropdownOptions, manualChargeDetailVM])

    const memoTarCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!isAdd}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.tariffCode || ''}
                fieldValue={currentManChgDtl?.tariffCode}
                fieldLabel={DTL_CONST.TAR_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffCode'}
                options={memoTariffCodeOptions}
                maxLength={60}
                requiredFieldList={manChgDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    manualChargeDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [DTL_CONST.TAR_CODE, allFormState, currentManChgDtl?.tariffCode, isAdd, isSaveClicked, manualChargeDetailVM, memoTariffCodeOptions])


    const memoTarDesc = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.tariffCodeDesc || ''}
                fieldValue={currentManChgDtl?.tariffCodeDesc}
                fieldLabel={DTL_CONST.TAR_DESC}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'tariffCodeDesc'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [DTL_CONST.TAR_DESC, currentManChgDtl?.tariffCodeDesc, isSaveClicked])


    const memoChargeOnCmp = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.chargeOnCompany ?? ''}
                fieldValue={currentManChgDtl?.chargeOnCompany ?? ''}
                fieldLabel={DTL_CONST.CHARGE_ON}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeOnCompany'}
                options={manualChargeDetailState.dynamicOptions.chargeOnCompDropdownOptions}
                maxLength={60}
                requiredFieldList={manChgDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    manualChargeDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [DTL_CONST.CHARGE_ON, allFormState, currentManChgDtl?.chargeOnCompany, isAdjustmentRead, isRead, isSaveClicked, manualChargeDetailState.dynamicOptions.chargeOnCompDropdownOptions, manualChargeDetailVM])


    const memoShiftCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.shiftCode || ''}
                fieldValue={currentManChgDtl?.shiftCode}
                fieldLabel={DTL_CONST.SHIFT_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'shiftCode'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [DTL_CONST.SHIFT_CODE, currentManChgDtl?.shiftCode, isSaveClicked, isRead])


    const memoCntrNo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "810px", marginBottom: "24px" }} >
            <HPHInputTextarea
                label={HDR_CONST.CNTR_NO}
                width="810px"
                rows={3}
                disabled={isRead || isAdjustmentRead}
                resizable={false}
                helpIcon={true}
                optional={true}
                toolTipText={"Use comma ( , ) as delimiter"}
                value={(/*manualChargeDetailState.cntrNoStr?manualChargeDetailState.cntrNoStr:*/currentManChgDtl?.cntrList) || ''}
                onChange={handleCntrTextAreaChange/*e=>manualChargeDetailVM.onCntrTextAreaChange(e,"cntrList")*/} />
        </div>
        , [HDR_CONST.CNTR_NO, isRead, isAdjustmentRead, currentManChgDtl?.cntrList, handleCntrTextAreaChange])

    const memoFromDate = useMemo(() =>
        <div className='im-flex-row-item'>
            <DatePickerComponent
                disabled={isRead || isAdjustmentRead}
                label={DTL_CONST.CHG_FROM_DATE}
                width="250px"
                date={currentManChgDtl?.fromDate}
                fieldName="fromDate"
                optional={true}
                errorMessage={allFormState ? allFormState["validateRangeDate"] : ""}
                onDateChange={manualChargeDetailVM.onFieldChange} />
        </div>
        , [isRead, isAdjustmentRead, DTL_CONST.CHG_FROM_DATE, currentManChgDtl?.fromDate, allFormState, manualChargeDetailVM.onFieldChange])

    const memoToDate = useMemo(() =>
        <div className='im-flex-row-item'>
            <DatePickerComponent
                disabled={isRead || isAdjustmentRead}
                label={DTL_CONST.CHG_TO_DATE}
                width="250px"
                date={currentManChgDtl?.toDate}
                fieldName="toDate"
                optional={true}
                errorMessage={allFormState ? allFormState["toDate"] : ""}
                onDateChange={manualChargeDetailVM.onFieldChange} />
        </div>
        , [isRead, isAdjustmentRead, DTL_CONST.CHG_TO_DATE, currentManChgDtl?.toDate, allFormState, manualChargeDetailVM.onFieldChange])


    const memoStoQty = useMemo(() =>

        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NumberInputComponent
                label={DTL_CONST.CHARGE_STO_QTY}
                maxLength={60}
                fieldName={"unitPerQty"}
                value={currentManChgDtl?.unitPerQty ?? ''}
                errorMessage={allFormState?.unitPerQty}
                disabled={true}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
            {/*<NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.unitPerQty?.toString() || ''}
                fieldValue={currentManChgDtl?.unitPerQty?.toString()}
                fieldLabel={DTL_CONST.CHARGE_STO_QTY}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'unitPerQty'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />*/}
        </div>

        , [DTL_CONST.CHARGE_STO_QTY, allFormState, currentManChgDtl?.unitPerQty, onNumberFieldChange])

    const memoChargingQty = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NumberInputComponent
                label={DTL_CONST.CHARGE_QTY}
                maxLength={60}
                fieldName={"chargeQty"}
                value={manualChargeDetailState.dtlTotalQty ? '' : (currentManChgDtl?.chargeQty ?? '')}
                errorMessage={allFormState?.chargeQty}
                disabled={isRead || !!currentManChgDtl.cntrList}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
            {/*<NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || !!currentManChgDtl.cntrList}
                isShowOptional={true}
                readOnlyValue={(manualChargeDetailState.dtlTotalQty??(currentManChgDtl?.chargeQty))?.toString() || ''}
                fieldValue={(manualChargeDetailState.dtlTotalQty??(currentManChgDtl?.chargeQty))?.toString()}
                fieldLabel={DTL_CONST.CHARGE_QTY}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'chargeQty'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={manChgDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    manualChargeDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />*/}
        </div>
        , [DTL_CONST.CHARGE_QTY, allFormState?.chargeQty, currentManChgDtl?.chargeQty, currentManChgDtl.cntrList, isRead, manualChargeDetailState.dtlTotalQty, onNumberFieldChange])

    const memoAdditionalQty = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NumberInputComponent
                label={DTL_CONST.ADDITION_QTY}
                maxLength={60}
                optional={true}
                fieldName={"additionalChargeQty"}
                value={currentManChgDtl?.additionalChargeQty ?? ''}
                errorMessage={allFormState?.additionalChargeQty}
                disabled={isRead || isAdjustmentRead}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
            {/*<NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.additionalChargeQty?.toString() || ''}
                fieldValue={currentManChgDtl?.additionalChargeQty?.toString()}
                fieldLabel={DTL_CONST.ADDITION_QTY}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'additionalChargeQty'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={manChgDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    manualChargeDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />*/}
        </div>
        , [DTL_CONST.ADDITION_QTY, allFormState, currentManChgDtl?.additionalChargeQty, isAdjustmentRead, isRead, onNumberFieldChange])

    const memoOneOffRate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NumberInputComponent
                label={DTL_CONST.ONE_OFF_RATE}
                maxLength={60}
                fieldName={"rate"}
                optional={true}
                value={currentManChgDtl?.rate ?? ''}
                errorMessage={allFormState?.rate}
                disabled={isRead}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
            {/*<NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.rate?.toString() || ''}
                fieldValue={currentManChgDtl?.rate?.toString()}
                fieldLabel={DTL_CONST.ONE_OFF_RATE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'rate'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={manChgDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    manualChargeDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />*/}
        </div>
        , [DTL_CONST.ONE_OFF_RATE, allFormState, currentManChgDtl?.rate, isRead, onNumberFieldChange])


    const memoCmAc = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NumberInputComponent
                label={DTL_CONST.COMMON_ACC}
                maxLength={60}
                optional={true}
                fieldName={"chargeSharePercentage"}
                value={currentManChgDtl?.chargeSharePercentage ?? ''}
                errorMessage={allFormState?.chargeSharePercentage}
                disabled={isRead || isAdjustmentRead}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
            {/*<NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.chargeSharePercentage?.toString() || ''}
                fieldValue={currentManChgDtl?.chargeSharePercentage}
                fieldLabel={DTL_CONST.COMMON_ACC}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'chargeSharePercentage'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={manChgDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    manualChargeDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />*/}
        </div>
        , [DTL_CONST.COMMON_ACC, allFormState, currentManChgDtl?.chargeSharePercentage, isAdjustmentRead, isRead, onNumberFieldChange])


    const memoSurcharge = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NumberInputComponent
                label={DTL_CONST.SURCHARGE}
                maxLength={60}
                optional={true}
                fieldName={"chargePercentage"}
                value={currentManChgDtl?.chargePercentage ?? ''}
                errorMessage={allFormState?.chargePercentage}
                disabled={isRead || isAdjustmentRead}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
            {/*<NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.chargePercentage?.toString() || ''}
                fieldValue={currentManChgDtl?.chargePercentage?.toString()}
                fieldLabel={DTL_CONST.SURCHARGE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'chargePercentage'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={manChgDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    manualChargeDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />*/}
        </div>
        , [DTL_CONST.SURCHARGE, allFormState, currentManChgDtl?.chargePercentage, isAdjustmentRead, isRead, onNumberFieldChange])

    const memoOneOffRatePercentage = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NumberInputComponent
                label={DTL_CONST.TAX_PERCENTAGE}
                maxLength={60}
                fieldName={"vatPercentage"}
                value={currentManChgDtl?.vatPercentage ?? ''}
                errorMessage={allFormState?.vatPercentage ?? ''}
                disabled={isRead || isAdjustmentRead}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
            {/*<NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.vatPercentage?.toString() || ''}
                fieldValue={currentManChgDtl?.vatPercentage?.toString()}
                fieldLabel={DTL_CONST.TAX_PERCENTAGE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'vatPercentage'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={manChgDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    manualChargeDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />*/}
        </div>
        , [DTL_CONST.TAX_PERCENTAGE, allFormState, currentManChgDtl?.vatPercentage, isAdjustmentRead, isRead, onNumberFieldChange])

    const memoLogNo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.logNo || ''}
                fieldValue={currentManChgDtl?.logNo}
                fieldLabel={DTL_CONST.LOG_NO}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'logNo'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [DTL_CONST.LOG_NO, allFormState, currentManChgDtl?.logNo, isSaveClicked])

    const memoTaxCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.vatOneOffTaxCode ?? ''}
                fieldValue={currentManChgDtl?.vatOneOffTaxCode ?? ''}
                fieldLabel={DTL_CONST.TAX_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'vatOneOffTaxCode'}
                options={manualChargeDetailState.dynamicOptions.taxCodeDropdownOptions}
                maxLength={60}
                requiredFieldList={manChgDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    manualChargeDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [DTL_CONST.TAX_CODE, allFormState, currentManChgDtl?.vatOneOffTaxCode, isAdjustmentRead, isRead, isSaveClicked, manualChargeDetailState.dynamicOptions.taxCodeDropdownOptions, manualChargeDetailVM])



    const memoRefNoAdjFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.adjRefNo || ''}
                fieldValue={currentManChgDtl?.adjRefNo}
                fieldLabel={DTL_CONST.REF_NO_ADJ_FROM}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'adjRefNo'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [DTL_CONST.REF_NO_ADJ_FROM, currentManChgDtl?.adjRefNo, isSaveClicked])

    const memoInvNo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.invoiceNo || ''}
                fieldValue={currentManChgDtl?.invoiceNo}
                fieldLabel={HDR_CONST.INV_NO}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'invoiceNo'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [HDR_CONST.INV_NO, currentManChgDtl?.invoiceNo, isSaveClicked])

    const memoCreditNoteNo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.creditNo || ''}
                fieldValue={currentManChgDtl?.creditNo}
                fieldLabel={HDR_CONST.CREDIT_NOTE_NO}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'creditNo'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [HDR_CONST.CREDIT_NOTE_NO, currentManChgDtl?.creditNo, isSaveClicked])

    const memoDraftInvoiceNo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.draftInvoiceNo || ''}
                fieldValue={currentManChgDtl?.draftInvoiceNo}
                fieldLabel={HDR_CONST.DRAFT_INV_NO}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'draftInvoiceNo'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [HDR_CONST.DRAFT_INV_NO, currentManChgDtl?.draftInvoiceNo, isSaveClicked])

    const memoDraftCreditNoteNo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.draftCreditNo || ''}
                fieldValue={currentManChgDtl?.draftCreditNo}
                fieldLabel={HDR_CONST.DRAFT_CREDIT_NOTE_NO}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'draftCreditNo'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [HDR_CONST.DRAFT_CREDIT_NOTE_NO, currentManChgDtl?.draftCreditNo, isSaveClicked])

    const memoAdjType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.adjType || ''}
                fieldValue={currentManChgDtl?.adjType}
                fieldLabel={DTL_CONST.ADJ_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'adjType'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [DTL_CONST.ADJ_TYPE, currentManChgDtl?.adjType, isSaveClicked])

    const memoUom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "140px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.individualChargeQtyUom ?? ''}
                fieldValue={currentManChgDtl?.individualChargeQtyUom ?? ''}
                fieldLabel={DTL_CONST.UOM}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'individualChargeQtyUom'}
                options={uomDropdownOption}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={manChgDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    manualChargeDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [DTL_CONST.UOM, allFormState, currentManChgDtl?.individualChargeQtyUom, isAdjustmentRead, isRead, isSaveClicked, manualChargeDetailVM])

    const memoExMsg = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.exceptionMsg || ''}
                fieldValue={currentManChgDtl?.exceptionMsg}
                fieldLabel={DTL_CONST.EX_MSG}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'exceptionMsg'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [DTL_CONST.EX_MSG, currentManChgDtl?.exceptionMsg, isSaveClicked])

    const memoTaxTarType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl.vatTarType ?? ''}
                fieldValue={currentManChgDtl.vatTarType ?? ''}
                fieldLabel={DTL_CONST.TAX_TAR_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'vatTarType'}
                options={manualChargeDetailState.dynamicOptions.taxTariffTypeDropdownOptions}
                maxLength={60}
                requiredFieldList={manChgDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    manualChargeDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [DTL_CONST.TAX_TAR_TYPE, allFormState, currentManChgDtl.vatTarType, isAdjustmentRead, isRead, isSaveClicked, manualChargeDetailState.dynamicOptions.taxTariffTypeDropdownOptions, manualChargeDetailVM])


    const memoTaxTarCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.vatTarCode ?? ''}
                fieldValue={currentManChgDtl?.vatTarCode ?? ''}
                fieldLabel={DTL_CONST.TAX_TAR_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'vatTarCode'}
                options={memoTaxTariffCodeOptions}
                maxLength={60}
                requiredFieldList={manChgDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    manualChargeDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [DTL_CONST.TAX_TAR_CODE, allFormState, currentManChgDtl?.vatTarCode, isAdjustmentRead, isRead, isSaveClicked, manualChargeDetailVM, memoTaxTariffCodeOptions])

    const memoTaxTarDesc = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.vatTariffCodeDesc || ''}
                fieldValue={currentManChgDtl?.vatTariffCodeDesc}
                fieldLabel={DTL_CONST.TAX_TAR_DESC}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'vatTariffCodeDesc'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [DTL_CONST.TAX_TAR_DESC, currentManChgDtl?.vatTariffCodeDesc, isSaveClicked])

    const memoArticleStatementCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.vatArticleStatementCode ?? ''}
                fieldValue={currentManChgDtl?.vatArticleStatementCode ?? ''}
                fieldLabel={DTL_CONST.ARTICLE_STATEMENT_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'vatArticleStatementCode'}
                options={manualChargeDetailState.dynamicOptions.articleStatementCodeDropdownOptions}
                maxLength={60}
                requiredFieldList={manChgDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    manualChargeDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [DTL_CONST.ARTICLE_STATEMENT_CODE, allFormState, currentManChgDtl?.vatArticleStatementCode, isAdjustmentRead, isRead, isSaveClicked, manualChargeDetailState.dynamicOptions.articleStatementCodeDropdownOptions, manualChargeDetailVM])



    const memoTxType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.vatTransactionType || ''}
                fieldValue={currentManChgDtl?.vatTransactionType}
                fieldLabel={DTL_CONST.TX_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'vatTransactionType'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [DTL_CONST.TX_TYPE, currentManChgDtl?.vatTransactionType, isSaveClicked])


    const memoArticleStatement = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.vatArticleStatement || ''}
                fieldValue={currentManChgDtl?.vatArticleStatement}
                fieldLabel={DTL_CONST.ARTICLE_STATEMENT}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'vatArticleStatement'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [DTL_CONST.ARTICLE_STATEMENT, currentManChgDtl?.vatArticleStatement, isSaveClicked])

    const memoVatDays = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={false}
                readOnlyValue={String(currentManChgDtl?.vatDays || '')}
                fieldValue={String(currentManChgDtl?.vatDays || '')}
                fieldLabel={DTL_CONST.TAX_PAYMENT_TERM_IN}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'vatDays'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={manChgDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    manualChargeDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [DTL_CONST.TAX_PAYMENT_TERM_IN, allFormState, currentManChgDtl?.vatDays, isAdjustmentRead, isRead, isSaveClicked, manualChargeDetailVM])

    const memoVatDaysCredit = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={false || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={String(currentManChgDtl?.vatDaysCredit ?? '')}
                fieldValue={String(currentManChgDtl?.vatDaysCredit ?? '')}
                fieldLabel={DTL_CONST.TAX_PAYMENT_TERM_CR}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'vatDaysCredit'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={manChgDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    manualChargeDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [DTL_CONST.TAX_PAYMENT_TERM_CR, allFormState, currentManChgDtl?.vatDaysCredit, isAdjustmentRead, isSaveClicked, manualChargeDetailVM])


    const memoVatDaysDebit = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={false || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={String(currentManChgDtl?.vatDaysDebit ?? '')}
                fieldValue={String(currentManChgDtl?.vatDaysDebit ?? '')}
                fieldLabel={DTL_CONST.TAX_PAYMENT_TERM_DEBIT}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'vatDaysDebit'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={manChgDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    manualChargeDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [DTL_CONST.TAX_PAYMENT_TERM_DEBIT, allFormState, currentManChgDtl?.vatDaysDebit, isAdjustmentRead, isSaveClicked, manualChargeDetailVM])

    const memoVatRefDate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={false || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.paymentReferenceDate ?? ''}
                fieldValue={currentManChgDtl?.paymentReferenceDate ?? ''}
                fieldLabel={DTL_CONST.TAX_PAYMENT_REF_TERM_IN}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'paymentReferenceDate'}
                isShowMissingError={false}
                options={vatPaymentReferenceDateDropdownOption}
                maxLength={60}
                requiredFieldList={manChgDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    manualChargeDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [DTL_CONST.TAX_PAYMENT_REF_TERM_IN, allFormState, currentManChgDtl?.paymentReferenceDate, isAdjustmentRead, isSaveClicked, manualChargeDetailVM])

    const memoVatRefDateCredit = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={false || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.paymentReferenceDateCredit ?? ''}
                fieldValue={currentManChgDtl?.paymentReferenceDateCredit ?? ''}
                fieldLabel={DTL_CONST.TAX_PAYMENT_REF_TERM_CR}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'paymentReferenceDateCredit'}
                isShowMissingError={false}
                options={vatPaymentReferenceDateDropdownOption}
                maxLength={60}
                requiredFieldList={manChgDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    manualChargeDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [DTL_CONST.TAX_PAYMENT_REF_TERM_CR, allFormState, currentManChgDtl?.paymentReferenceDateCredit, isAdjustmentRead, isSaveClicked, manualChargeDetailVM])

    const memoVatRefDateDebit = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={false || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.paymentReferenceDateDebit ?? ''}
                fieldValue={currentManChgDtl?.paymentReferenceDateDebit ?? ''}
                fieldLabel={DTL_CONST.TAX_PAYMENT_REF_TERM_DEBIT}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'paymentReferenceDateDebit'}
                isShowMissingError={false}
                options={vatPaymentReferenceDateDropdownOption}
                maxLength={60}
                requiredFieldList={manChgDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    manualChargeDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [DTL_CONST.TAX_PAYMENT_REF_TERM_DEBIT, allFormState, currentManChgDtl?.paymentReferenceDateDebit, isAdjustmentRead, isSaveClicked, manualChargeDetailVM])

    const memoChargeInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.chargeInd ?? ''}
                fieldValue={currentManChgDtl?.chargeInd ?? ''}
                fieldLabel={DTL_CONST.CHARGE_IND}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeInd'}
                options={manualChargeDetailState.dynamicOptions.chargeIndDropdownOptions}
                maxLength={60}
                requiredFieldList={manChgDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    manualChargeDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [DTL_CONST.CHARGE_IND, allFormState, currentManChgDtl?.chargeInd, isAdjustmentRead, isRead, isSaveClicked, manualChargeDetailState.dynamicOptions.chargeIndDropdownOptions, manualChargeDetailVM])


    const memoFwCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.forwarderCode ?? ''}
                fieldValue={currentManChgDtl?.forwarderCode ?? ''}
                fieldLabel={DTL_CONST.FW_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'forwarderCode'}
                options={manualChargeDetailState.dynamicOptions.forwardCodeDropdownOptions}
                maxLength={60}
                requiredFieldList={manChgDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    manualChargeDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [DTL_CONST.FW_CODE, allFormState, currentManChgDtl?.forwarderCode, isAdjustmentRead, isRead, isSaveClicked, manualChargeDetailState.dynamicOptions.forwardCodeDropdownOptions, manualChargeDetailVM])


    const memoEmptyInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.emptyIndicator ?? ''}
                fieldValue={currentManChgDtl?.emptyIndicator ?? ''}
                fieldLabel={DTL_CONST.EMPTY_IND}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'emptyIndicator'}
                options={emptyIndDropdownOption}
                maxLength={60}
                requiredFieldList={manChgDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    manualChargeDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [DTL_CONST.EMPTY_IND, allFormState, currentManChgDtl?.emptyIndicator, isAdjustmentRead, isRead, isSaveClicked, manualChargeDetailVM])



    const memoPartnerCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.partnerCode ?? ''}
                fieldValue={currentManChgDtl?.partnerCode ?? ''}
                fieldLabel={DTL_CONST.PARTNER}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'partnerCode'}
                options={manualChargeDetailState.dynamicOptions.partnerCodeDropdownOptions}
                maxLength={60}
                requiredFieldList={manChgDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    manualChargeDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [DTL_CONST.PARTNER, allFormState, currentManChgDtl?.partnerCode, isAdjustmentRead, isRead, isSaveClicked, manualChargeDetailState.dynamicOptions.partnerCodeDropdownOptions, manualChargeDetailVM])



    const memoTspStatus = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.cntrTspStatus ?? ''}
                fieldValue={currentManChgDtl?.cntrTspStatus ?? ''}
                fieldLabel={DTL_CONST.TS_STATUS}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'cntrTspStatus'}
                options={cntrTspStatusDropdownOption}
                maxLength={60}
                requiredFieldList={manChgDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    manualChargeDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [DTL_CONST.TS_STATUS, allFormState, currentManChgDtl?.cntrTspStatus, isAdjustmentRead, isRead, isSaveClicked, manualChargeDetailVM])

    const memoOperationType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.operationType ?? ''}
                fieldValue={currentManChgDtl?.operationType ?? ''}
                fieldLabel={DTL_CONST.OP_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'operationType'}
                options={manualChargeDetailState.dynamicOptions.operationTypeDropdownOptions}
                maxLength={60}
                requiredFieldList={manChgDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    manualChargeDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [DTL_CONST.OP_TYPE, allFormState, currentManChgDtl?.operationType, isAdjustmentRead, isRead, isSaveClicked, manualChargeDetailState.dynamicOptions.operationTypeDropdownOptions, manualChargeDetailVM])

    const memoReasonCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.reasonCode || ''}
                fieldValue={currentManChgDtl?.reasonCode}
                fieldLabel={DTL_CONST.REASON_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'reasonCode'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [DTL_CONST.REASON_CODE, currentManChgDtl?.reasonCode, isSaveClicked])


    return (
        <div className='side-form-content-wrapper'>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{'Detail'}</SidebarTitle>
                    </Sidebarheader>
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" />}

            <div className={'add-edit-form'} style={{ height: '75vh', maxHeight: '75vh', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <CriteriaItemContainer>
                            {memoState}
                            {memoTarType}
                            {memoTarCode}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoTarDesc}
                            {memoChargeOnCmp}
                            {memoShiftCode}
                            {/*<HPHButton label={DTL_CONST.GET_SHIFT} disabled={isRead || isAdjustmentRead} size={'Small'} theme={'Secondary'} onClick={handleGetShift} /> */}
                            {(!isRead && !isAdjustmentRead) && <><div className='password-eye manual-dtl-shift-code'>
                                <IconButton fileName="Icon-clock" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Get Shift'} onClick={handleGetShift} /></div></>}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            <div className={"customContainer"}>
                                {memoCntrNo}
                                {(!isRead && !isAdjustmentRead) && <><div className='password-eye manual-dtl-cntr-dtl'>
                                    <IconButton fileName="Icon-search" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Container Detail'} onClick={handleCntrDetail} /></div></>}
                                {(!isRead && !isAdjustmentRead) && <><div className='password-eye manual-dtl-upload-cntr'>
                                    <IconButton fileName="Icon-upload" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Upload Container'} onClick={handleUploadCntr} />
                                    <input ref={uploadRef} type="file" accept=".txt" style={{ display: 'none' }} onChange={handleFileChange} /></div></>}
                                {/*<HPHButton label={DTL_CONST.CNTR_DTL} disabled={isRead || isAdjustmentRead} size={'Small'} theme={'Secondary'} onClick={handleCntrDetail} />
                                <FileUpload label={WorkspaceConstant.Common.BUTTON_UPLOADCNTR} disabled={isRead || isAdjustmentRead} accept=".txt" size={"Small"} theme={"Secondary"} handleUpload={handleUploadCntr}/>*/}

                            </div>
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoFromDate}
                            {memoToDate}
                            {memoStoQty}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoChargingQty}
                            {memoAdditionalQty}
                            {memoOneOffRate}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoCmAc}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoSurcharge}
                            {memoOneOffRatePercentage}
                            {memoLogNo}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoTaxCode}
                            {memoRefNoAdjFrom}
                            {memoInvNo}
                        </CriteriaItemContainer>


                        <CriteriaItemContainer>
                            {memoCreditNoteNo}
                            {memoDraftInvoiceNo}
                            {memoDraftCreditNoteNo}
                        </CriteriaItemContainer>


                        <CriteriaItemContainer>
                            {memoAdjType}
                            {memoUom}
                            <HPHButton label={DTL_CONST.GET_UOM} disabled={isRead || isAdjustmentRead} size={'Small'} theme={'Secondary'} onClick={handleGetUom} />
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoTaxTarType}
                            {memoTaxTarCode}
                            {memoTaxTarDesc}

                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoArticleStatementCode}
                            {memoTxType}
                            {memoArticleStatement}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoVatDays}
                            {memoVatDaysCredit}
                            {memoVatDaysDebit}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoVatRefDate}
                            {memoVatRefDateCredit}
                            {memoVatRefDateDebit}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoChargeInd}
                            {memoFwCode}
                            {memoEmptyInd}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoPartnerCode}
                            {memoTspStatus}
                            {memoOperationType}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoReasonCode}
                            {memoExMsg}
                        </CriteriaItemContainer>

                    </div>
                </div>
            </div>

            <div className='im-charge-data-search-criteria-buttons'>
                {
                    <>
                        <HPHButton label={isRead ? 'Close' : 'Cancel'} size={'Small'} theme={'Secondary'} onClick={handleCancelClick} />
                        {!isRead && <HPHButton label={isAdd ? 'Add' : isEditable ? 'Update' : 'Save'} size={'Small'} theme={'Primary'} onClick={() => handleSaveClick()} />}
                    </>
                }
            </div>


            {manualChargeDetailState.isShowFindCntrPanel && <ManualChargeCntrComp />}
            {manualChargeDetailState.isShowFindVesselPanel && <ManualChargeVesselComp />}
        </div>
    )
}