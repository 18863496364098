import { AdjustmentTypePolicy } from "constants/charge/AdjustmentTypePolicy";
import { ChargeConstantCollection } from "constants/charge/ChargeConstantCollection";
import { EMPTY_MANUAL_CHARGE_DETAIL_ADJUSTMENT_REQUEST_ENTITY, ManualChargeDetailAdjustmentRequestEntity } from "domain/entity/ManualCharge/ManualChargeDetailAdjustmentRequestEntity";
import { EMPTY_MANUAL_CHARGE_DETAIL_ENTITY, ManualChargeDetailEntity } from "domain/entity/ManualCharge/ManualChargeDetailEntity";
import { EMPTY_MANUAL_CHARGE_HEADER_ENTITY, ManualChargeHeaderEntity } from "domain/entity/ManualCharge/ManualChargeHeaderEntity";
import { MasterDataType } from "domain/entity/MasterData/MasterDataEntity";
import { ParameterDetailEntity } from "domain/entity/Parameter/ParameterDetailEntity";
import { ChargeTypeRepository } from "domain/repository/ChargeType/ChargeTypeRepo";
import { SearchPreferenceRepository } from "domain/repository/Common/SearchPreferenceRepo";
import { ShiftRepository } from "domain/repository/Common/ShiftRepo";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { ManualChargeDetailRepository } from "domain/repository/ManualCharge/ManualChargeDetailRepo";
import { ManualChargeHeaderRepository } from "domain/repository/ManualCharge/ManualChargeHeaderRepo";
import { MasterDataRepository } from "domain/repository/MasterData/MasterDataRepo";
import { StandardTariffCodeRepository } from "domain/repository/TariffCode/StandardTariffCodeRepo";
import { TariffProposalRepository } from "domain/repository/TariffCode/TariffProposalRepo";
import { TariffTypeRepository } from "domain/repository/TariffCode/TariffTypeRepo";
import { ParameterDetailRepository } from "domain/repository/parameter/ParameterDetailRepo";
import { ParameterRepository } from "domain/repository/parameter/ParameterRepo";
import _ from "lodash";
import { updateAdjustmentValidationSchema } from "presentation/constant/ChargeDetailMaintenance/ChargeDetailValidationSchema";
import { ShiftType } from "presentation/constant/Common/ShiftType";
import { uomDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { manChgDtl2dpNumberFieldList, manChgDtl4dpNumberFieldList, manChgDtlPositiveIntegerNumberFieldList } from "presentation/constant/ManualCharge/ManualChargeConstant";
import { ManualChargeHeaderSearchCriteria } from "presentation/constant/ManualCharge/ManualChargeHeaderSearchCriteria";
import { createManChgDtlValidationSchema, createManChgHdrValidationSchema, updateManChgDtlValidationSchema, updateManChgHdrValidationSchema } from "presentation/constant/ManualCharge/ManualChargeValidationSchema";
import { DEFAULT_ENABLED_MANUAL_CHARGE_HEADER_SEARCH_CRITERIA, ManualChargeHeaderEnabledSearchCriteria } from "presentation/constant/ManualCharge/ManualHeaderEnabledSearchCriteria";
import { ParameterCodeValue, ParameterDetailCodeValue } from "presentation/constant/Parameter/ParameterCodeValue";
import { Validation } from "presentation/constant/Validation";
import { DropdownProps } from "presentation/model/DropdownProps";
import { EMPTY_MANUAL_CHARGE_DETAIL_MODEL, ManualChargeDetailModel } from "presentation/model/ManualChargeMaintenance/ManualChargeDetailModel";
import { ManualChargeHeaderMaintenanceModel } from "presentation/model/ManualChargeMaintenance/ManualChargeHeaderMaintenanceModel";
import BaseViewModel from "presentation/viewModel/BaseViewModel";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";

interface ManualChargeDetailVMVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<ManualChargeDetailModel>> | ((value: SetStateAction<ManualChargeDetailModel>) => void),
    ],
    hdrDispatch: [
        Dispatch<SetStateAction<ManualChargeHeaderMaintenanceModel>> | ((value: SetStateAction<ManualChargeHeaderMaintenanceModel>) => void),
    ]
    chargeTypeRepo: ChargeTypeRepository,
    companyRepo: CompanyRepository,
    searchPreferenceRepo: SearchPreferenceRepository<ManualChargeHeaderSearchCriteria, ManualChargeHeaderEnabledSearchCriteria>,
    manualChargeHeaderRepo: ManualChargeHeaderRepository,
    manualChargeDetailRepo: ManualChargeDetailRepository,
    masterDataRepo: MasterDataRepository,
    standardTariffCodeRepo: StandardTariffCodeRepository,
    tariffTypeRepo: TariffTypeRepository,
    parameterDetailRepo: ParameterDetailRepository,
    shiftRepo: ShiftRepository,
    tariffProposalRepo: TariffProposalRepository,
    parameterRepo: ParameterRepository,
}

export const ManualChargeDetailVM = ({ dispatch, hdrDispatch, chargeTypeRepo, companyRepo, searchPreferenceRepo,
    manualChargeHeaderRepo, manualChargeDetailRepo, masterDataRepo, standardTariffCodeRepo, tariffTypeRepo, parameterDetailRepo, shiftRepo, tariffProposalRepo, parameterRepo }: ManualChargeDetailVMVMProps) => {
    const [manChgDtlDispatch] = dispatch;
    const [manChgHdrDispatch] = hdrDispatch;


    const onPageInit = (currentManChgHdr: ManualChargeHeaderEntity | null) => {
        const manChargeHdr = currentManChgHdr ?? EMPTY_MANUAL_CHARGE_HEADER_ENTITY;

        let isEditable = !!(currentManChgHdr?.id) && (currentManChgHdr?.hdrState === ChargeConstantCollection.manHdrState.NIL);

        manChgDtlDispatch(prevState => {
            return {
                ...prevState,
                currentManualChargeHeader: {
                    ...manChargeHdr,
                },
                selectedManualChargeDetailRows: [],
                viewState: {
                    ...prevState.viewState,
                    isAdd: currentManChgHdr?.id === null,
                    isEditable: isEditable,
                    isRead: currentManChgHdr?.id !== null,
                    editingHeader: {
                        ...manChargeHdr,
                    },
                }
            }
        })
    }

    const onCloseScreen = () => {
        manChgDtlDispatch(prevState => {
            return {
                ...EMPTY_MANUAL_CHARGE_DETAIL_MODEL,
            }
        })

        manChgHdrDispatch(prevState => {
            return {
                ...prevState,
                manualChargeHeaders: [],
                isShowDetail: false,
                isBackFromDetail: true,
                isAllowAutoSearch: true,
            }
        })
    }

    const onShowLoading = () => {
        manChgDtlDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        manChgDtlDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }

    const updateSelectedManualChargeDetails = (rows: any[]) => {
        manChgDtlDispatch(prevState => {
            return {
                ...prevState,
                selectedManualChargeDetailRows: rows
            }
        })
    }

    const onSelectedSingleChargeDetail = (row: ManualChargeDetailEntity) => {
        manChgDtlDispatch(prevState => {
            return {
                ...prevState,
                viewState: {
                    ...prevState.viewState,
                    currentSelectedData: row
                }
            }
        })
    }



    const updateCheckboxRef = (checkboxRef: { [key: string]: HTMLElement | null }) => {
        manChgDtlDispatch(prevState => ({
            ...prevState,
            checkboxRef: checkboxRef
        }))
    }



    const onResetCheckboxValue = () => {
        manChgDtlDispatch(prevState => {
            return {
                ...prevState,
                checkboxValue: { ...DEFAULT_ENABLED_MANUAL_CHARGE_HEADER_SEARCH_CRITERIA },
            }
        })
    }




    const onSearch = async (manChgHdrId: number) => {
        // DomUtils.addWithSearchClass();

        manChgDtlDispatch(prevState => {
            return {
                ...prevState,
                manualChargeDetails: [],
                selectedManualChargeDetailRows: [],
            }
        })

        manualChargeDetailRepo.getManualChargeDetailByHdrId(manChgHdrId).then((data) => {
            manChgDtlDispatch(prevState => {
                return {
                    ...prevState,
                    manualChargeDetails: data,
                    currentManualChargeHeader: data[0].manualChargeHdr ? data[0].manualChargeHdr : prevState.currentManualChargeHeader,
                    selectedManualChargeDetailRows: [],
                    viewState: {
                        ...prevState.viewState,
                        editingHeader: {
                            ...EMPTY_MANUAL_CHARGE_HEADER_ENTITY,
                        },
                        currentSelectedData: EMPTY_MANUAL_CHARGE_DETAIL_ENTITY,
                    }
                }
            })
        })
    }

    const loadDropdownOption = async () => {
        await chargeTypeRepo.getChargeTypeByRole('USER').then(
            chargeTypes => {
                let newChargeTypes = _.orderBy(chargeTypes, ["chargeType", "subChargeType"]);
                let chargeTypeDropdownOptions: DropdownProps[] = [];
                newChargeTypes?.forEach(chgTypeEty => {
                    const chgTypeExisted = chargeTypeDropdownOptions.find(chgType =>
                        chgType.value === chgTypeEty.chargeType);
                    if (!chgTypeExisted) {
                        chargeTypeDropdownOptions.push({
                            dropdownLabel: chgTypeEty.chargeType,
                            tagLabel: chgTypeEty.chargeType,
                            value: chgTypeEty.chargeType
                        })
                    }
                });

                manChgDtlDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chargeTypeDropdownOptions: chargeTypeDropdownOptions,
                    },

                }))
            }
        )

        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let companyCodeDropdownOptions = companies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.companyCode,
                    value: company.companyCode,
                })) ?? []
                companyCodeDropdownOptions = _.orderBy(companyCodeDropdownOptions, "dropdownLabel");

                manChgDtlDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        billToCompDropdownOptions: companyCodeDropdownOptions,
                        chargeOnCompDropdownOptions: companyCodeDropdownOptions,
                        partnerCodeDropdownOptions: companyCodeDropdownOptions,


                    }
                }))
            }
        )

        await masterDataRepo.getMasterDataByKey(MasterDataType.TERMINAL).then(
            operatingTmls => {
                const operatingTmlDropdownOptions = operatingTmls?.map((opTml) => ({
                    dropdownLabel: opTml.code,
                    tagLabel: opTml.code,
                    value: opTml.code,
                })) ?? []

                manChgDtlDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        operatingTmlDropdownOptions: [
                            ...operatingTmlDropdownOptions
                        ]
                    },
                }))
            }
        )

        await masterDataRepo.getMasterDataByKey(MasterDataType.SERVICE).then(
            serviceCodes => {
                const serviceCodeDropdownOptions = serviceCodes?.map((serviceCode) => ({
                    dropdownLabel: serviceCode.code,
                    tagLabel: serviceCode.code,
                    value: serviceCode.code,
                })) ?? []

                manChgDtlDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        serviceCodeDropdownOptions: [
                            ...serviceCodeDropdownOptions
                        ]
                    }
                }))
            }
        )

        await masterDataRepo.getMasterDataByKey(MasterDataType.CHARGE_IND).then(
            chargeInds => {
                const chargeIndDropdownOptions = chargeInds?.map((chargeInd) => ({
                    dropdownLabel: chargeInd.code,
                    tagLabel: chargeInd.code,
                    value: chargeInd.code,
                })) ?? []

                manChgDtlDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chargeIndDropdownOptions: [
                            ...chargeIndDropdownOptions
                        ]
                    }
                }))
            }
        )

        await masterDataRepo.getMasterDataByKey(MasterDataType.FORWARDER_CODE).then(
            fwCodes => {
                const fwCodeDropdownOptions = fwCodes?.map((fwCode) => ({
                    dropdownLabel: fwCode.code,
                    tagLabel: fwCode.code,
                    value: fwCode.code,
                })) ?? []

                manChgDtlDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        forwardCodeDropdownOptions: [
                            ...fwCodeDropdownOptions
                        ]
                    }
                }))
            }
        )

        await standardTariffCodeRepo.getAllStandardTariffCodes().then(
            tariffs => {

                let newTariffs = _.orderBy(tariffs, ["tariffType", "tariffCode"]);
                let tariffTypeDropdownOptions: DropdownProps[] = [];
                let tariffCodeDropdownOptions: { [key: string]: DropdownProps[] } = {};
                let allTariffCodeeDropdownOptions: DropdownProps[] = [];

                newTariffs?.forEach(tariff => {
                    const isTariffTypeExisted = tariffTypeDropdownOptions.find(t =>
                        t.value === tariff.tariffType);
                    if (!isTariffTypeExisted) {
                        tariffTypeDropdownOptions.push({
                            dropdownLabel: tariff.tariffType,
                            tagLabel: tariff.tariffType,
                            value: tariff.tariffType,
                        })
                    }

                    const isTariffCodeExisted = allTariffCodeeDropdownOptions.find(t =>
                        t.value === tariff.tariffCode);

                    if (!isTariffCodeExisted) {
                        allTariffCodeeDropdownOptions.push({
                            dropdownLabel: tariff.tariffCode,
                            tagLabel: tariff.tariffCode,
                            value: tariff.tariffCode,
                        })
                    }

                    if (tariff.tariffCode) {
                        if (!tariffCodeDropdownOptions[tariff.tariffType]) {
                            tariffCodeDropdownOptions[tariff.tariffType] = [];
                        }
                        tariffCodeDropdownOptions[tariff.tariffType].push({
                            dropdownLabel: tariff.tariffCode,
                            tagLabel: tariff.tariffCode,
                            value: tariff.tariffCode,
                        })

                    }
                })

                manChgDtlDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tariffTypeDropdownOptions: tariffTypeDropdownOptions,
                        taxTariffTypeDropdownOptions: tariffTypeDropdownOptions,
                        tariffCodeDropdownOptions: tariffCodeDropdownOptions,
                        taxTariffCodeDropdownOptions: tariffCodeDropdownOptions,
                        allTariffCodeeDropdownOptions: allTariffCodeeDropdownOptions,
                    }
                }))
            }
        )

        // await tariffTypeRepo.getAllActiveTariffTypes().then(
        //      tarTypes => {
        //          const tariffTypeDropdownOptions = tarTypes?.map((result) => ({
        //                  dropdownLabel: result.tariffType,
        //                  tagLabel: result.tariffType,
        //                  value: result.tariffType,
        //          })) ?? [];

        //          manChgDtlDispatch(prevState => ({
        //              ...prevState,
        //              dynamicOptions: {
        //                  ...prevState.dynamicOptions,
        //                  tariffTypeDropdownOptions: tariffTypeDropdownOptions,
        //                  taxTariffTypeDropdownOptions: tariffTypeDropdownOptions,
        //              }
        //          }))
        //      }
        //  )

        await parameterDetailRepo.getAllParameterDtlsByParameterCode(ParameterCodeValue.ARTICLE_STMT_CODE).then(
            parDtls => {
                const vatArticleStatementCodes = parDtls?.map((parDtl) => ({
                    dropdownLabel: parDtl.parameterDtlCode,
                    tagLabel: parDtl.parameterDtlCode,
                    value: parDtl.parameterDtlCode,
                })) ?? []

                manChgDtlDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        articleStatementCodeDropdownOptions: [...vatArticleStatementCodes]
                    }
                }))
            }
        )

        await parameterDetailRepo.getAllParameterDtlsByParameterCode(ParameterCodeValue.OPERATION_TYPE).then(
            parDtls => {
                const operationTypes = parDtls?.map((parDtl) => ({
                    dropdownLabel: parDtl.parameterDtlCode,
                    tagLabel: parDtl.parameterDtlCode,
                    value: parDtl.parameterDtlCode,
                })) ?? []

                manChgDtlDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        operationTypeDropdownOptions: operationTypes
                    }
                }))
            }
        )

        await parameterDetailRepo.getAllParameterDtlsByParameterCode(ParameterCodeValue.TAX_CODE).then(
            parDtls => {
                const taxCodes = parDtls?.map((parDtl) => ({
                    dropdownLabel: parDtl.parameterDtlCode,
                    tagLabel: parDtl.parameterDtlCode,
                    value: parDtl.parameterDtlCode,
                })) ?? []

                manChgDtlDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        taxCodeDropdownOptions: taxCodes
                    }
                }))
            }
        )

        await shiftRepo.getShiftByType(ShiftType.SHIFT_INTENSIVE).then(
            shiftList => {
                const shifts = shiftList?.map((shift) => ({
                    dropdownLabel: shift.shiftCode,
                    tagLabel: shift.shiftCode,
                    value: shift.shiftCode,
                })) ?? []

                manChgDtlDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        shiftsDropdownOptions: shifts
                    }
                }))
            }
        )

        //get accResumeTerminalReasonCode DropdownOption from parameter
        await parameterDetailRepo.getAllParameterDtlsByParameterCode("ACCT_TML_REASON_CODE").then(
            accReasonCodeList => {
                const accReasonCodeDropdownOption = accReasonCodeList?.map((reasonCode) => ({
                    dropdownLabel: reasonCode.parameterDtlCode,
                    tagLabel: reasonCode.parameterDtlCode,
                    value: reasonCode.parameterDtlCode,
                })) ?? []

                manChgDtlDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        reasonCodeDropdownOptions: accReasonCodeDropdownOption
                    }
                }))
            }
        )
    }

    const onInitDefaultValue = async (initHeaderForm: boolean) => {
        if (initHeaderForm) {
            let defaultOperatingTml: any = "";
            await parameterRepo.getParameterEtyByParameterCode(ParameterCodeValue.DEFAULT_OPERATING_TML).then(
                parDtl => {
                    defaultOperatingTml = parDtl ? parDtl.parameterDesc : "";
                }
            )
            manChgDtlDispatch(prevState => {
                let itemExists: any;
                if (prevState.dynamicOptions.operatingTmlDropdownOptions.length > 0) {
                    itemExists = prevState.dynamicOptions.operatingTmlDropdownOptions.find(item => item.value === defaultOperatingTml);
                    defaultOperatingTml = itemExists ? defaultOperatingTml : "";
                }
                return {
                    ...prevState,
                    currentManualChargeHeader: {
                        ...prevState.currentManualChargeHeader,
                        chargeType: prevState.currentManualChargeHeader.id !== null ? prevState.currentManualChargeHeader.chargeType
                            : (prevState.dynamicOptions.chargeTypeDropdownOptions.length > 0 ? prevState.dynamicOptions.chargeTypeDropdownOptions[0].value : ''),
                        operatingTml: prevState.currentManualChargeHeader.id !== null ? prevState.currentManualChargeHeader.operatingTml
                            : defaultOperatingTml ? defaultOperatingTml : (prevState.dynamicOptions.operatingTmlDropdownOptions.length > 0 ? prevState.dynamicOptions.operatingTmlDropdownOptions[0].value : ''),
                    },
                    viewState: {
                        ...prevState.viewState,
                        editingHeader: {
                            ...prevState.viewState.editingHeader,
                            chargeType: prevState.viewState.editingHeader.id !== null ? prevState.viewState.editingHeader.chargeType
                                : (prevState.dynamicOptions.chargeTypeDropdownOptions.length > 0 ? prevState.dynamicOptions.chargeTypeDropdownOptions[0].value : ''),
                            operatingTml: prevState.viewState.editingHeader.id !== null ? prevState.viewState.editingHeader.operatingTml
                                : defaultOperatingTml ? defaultOperatingTml : (prevState.dynamicOptions.operatingTmlDropdownOptions.length > 0 ? prevState.dynamicOptions.operatingTmlDropdownOptions[0].value : '')
                        },
                    }
                }
            })
        } else {
            manChgDtlDispatch(prevState => ({
                ...prevState,
                viewState: {
                    ...prevState.viewState,
                    currentSelectedData: {
                        ...prevState.viewState.currentSelectedData,
                        tariffType: prevState.viewState.currentSelectedData.id !== null ? prevState.viewState.currentSelectedData.tariffType
                            : (prevState.dynamicOptions.tariffTypeDropdownOptions.length > 0 ? prevState.dynamicOptions.tariffTypeDropdownOptions[0].value : ''),
                        tariffCode: prevState.viewState.currentSelectedData.id !== null ? prevState.viewState.currentSelectedData.tariffCode
                            : (prevState.dynamicOptions.tariffCodeDropdownOptions[prevState.dynamicOptions.tariffTypeDropdownOptions[0].value].length > 0 ?
                                prevState.dynamicOptions.tariffCodeDropdownOptions[prevState.dynamicOptions.tariffTypeDropdownOptions[0].value][0].value : ''),
                        chargeInd: prevState.viewState.currentSelectedData.id !== null ? prevState.viewState.currentSelectedData.chargeInd
                            : (prevState.dynamicOptions.chargeIndDropdownOptions.length > 0 ? prevState.dynamicOptions.chargeIndDropdownOptions[0].value : '')
                    }
                }
            }))
        }

    }

    const onAdd = () => {
        manChgDtlDispatch(prevState => {
            return {
                ...prevState,
                cntrNoStr: null,
                viewState: {
                    ...prevState.viewState,
                    isShowDetailInfo: true,
                    isAdd: true,
                    isRead: false,
                    isSaveClicked: false,
                    currentSelectedData: {
                        ...EMPTY_MANUAL_CHARGE_DETAIL_ENTITY,
                        dummyKey: _.uniqueId(),
                    },
                }
            }
        })
    }

    const onEdit = (manHdr: ManualChargeHeaderEntity, manualChargeDetails: ManualChargeDetailEntity[]) => {
        let isAdjustmentRead: boolean = false;
        if (manualChargeDetails && manualChargeDetails.length > 0) {
            if (manualChargeDetails[0].adjType === AdjustmentTypePolicy.ADJ_TYPE_ADJUSTMENT) {
                isAdjustmentRead = true;
            }
        }
        manChgDtlDispatch(prevState => {
            return {
                ...prevState,
                viewState: {
                    ...prevState.viewState,
                    isAdd: false,
                    isRead: false,
                    isAdjustmentRead: isAdjustmentRead,
                    isEditable: true,
                    editingHeader: {
                        ...manHdr,
                    },
                }
            }
        })
    }

    const onCancel = () => {
        manChgDtlDispatch(prevState => {
            return {
                ...prevState,
                viewState: {
                    ...prevState.viewState,
                    editingHeader: {
                        ...EMPTY_MANUAL_CHARGE_HEADER_ENTITY,
                    },
                    currentSelectedData: EMPTY_MANUAL_CHARGE_DETAIL_ENTITY,
                    isRead: true,
                    isEditable: true,
                    isSliderOpen: false,
                    allowAutoRefresh: false,
                    allFormState: {},
                }
            }
        })
    }

    const onCancelDetail = () => {
        manChgDtlDispatch(prevState => {
            return {
                ...prevState,
                viewState: {
                    ...prevState.viewState,
                    currentSelectedData: EMPTY_MANUAL_CHARGE_DETAIL_ENTITY,
                    isShowDetailInfo: false,
                    isEditable: true,
                    allowAutoRefresh: false,
                    isAdd: false,
                    allFormState: {},
                }
            }
        })
    }

    const onSaveClicked = () => {
        manChgDtlDispatch(prevState => {
            return {
                ...prevState,
                viewState: {
                    ...prevState.viewState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }

    const onRowClick = (row: ManualChargeDetailEntity) => {
        manChgDtlDispatch(prevState => {
            return {
                ...prevState,
                viewState: {
                    ...prevState.viewState,
                    currentSelectedData: row,
                    isShowDetailInfo: true,
                    isAdd: false,
                    allFormState: {},
                }
            }
        });
    }

    const onSave = async (currentHdr: ManualChargeHeaderEntity, dtls: ManualChargeDetailEntity[], isAdd: boolean) => {
        const valHdrResult = await Validation(isAdd ? createManChgHdrValidationSchema : updateManChgHdrValidationSchema).ValidateFormOnly(currentHdr);

        if (valHdrResult) {
            let allValResult: { [x: string]: string } = {};
            if (valHdrResult) {
                allValResult = { ...allValResult, ...valHdrResult, mandatoryCheckFail: 'Please input the missing value.' };
            }



            manChgDtlDispatch(prevState => {
                return {
                    ...prevState,
                    viewState: {
                        ...prevState.viewState,
                        allFormState: {
                            ...allValResult
                        },

                    }
                }
            });
            return allValResult;
        } else {
            const savedManHdr = await manualChargeDetailRepo.saveAll({
                manChgHdr: currentHdr,
                manChgDtls: dtls,
                chargeCategory: "MC"
            }).then((res) => {
                if (res.code === "200") {
                    const manHdr = res.data as ManualChargeHeaderEntity;

                    manChgDtlDispatch(prevState => {
                        return {
                            ...prevState,
                            manualChargeDetails: [],
                            currentManualChargeHeader: {
                                ...manHdr,
                            },
                            viewState: {
                                ...prevState.viewState,
                                isAdd: false,
                                isRead: true,
                                isEditable: true,
                                isSaveClicked: false,
                                editingHeader: {
                                    ...EMPTY_MANUAL_CHARGE_HEADER_ENTITY,
                                },
                                currentSelectedData: EMPTY_MANUAL_CHARGE_DETAIL_ENTITY,
                                lastEditRowId: '',
                                isSliderOpen: false,
                                allowAutoRefresh: false,
                            }
                        }
                    })

                    return manHdr;
                } else {
                    //throw new Error(res.data as string)
                    let allValResult: { [x: string]: string } = {};
                    allValResult = {
                        mandatoryCheckFail: res.data
                    };
                    return allValResult;
                }
            })

            return savedManHdr;

        }
    }

    const onSaveDetail = async (details: ManualChargeDetailEntity[], currentDtl: ManualChargeDetailEntity, isAdd: boolean) => {
        // const valHdrResult = await Validation(isAdd ? createManChgHdrValidationSchema : updateManChgHdrValidationSchema).ValidateFormOnly(EMPTY_MANUAL_CHARGE_HEADER_ENTITY);
        const valResult = await Validation(isAdd ? createManChgDtlValidationSchema : updateManChgDtlValidationSchema).ValidateFormOnly(currentDtl);


        if (valResult) {
            let allValResult: { [x: string]: string } = {};

            if (valResult) {
                allValResult = {
                    ...allValResult, ...valResult,
                    mandatoryCheckFail: 'Please input the missing value.'
                };
            }


            manChgDtlDispatch(prevState => {
                return {
                    ...prevState,
                    viewState: {
                        ...prevState.viewState,
                        allFormState: {
                            ...allValResult,
                        },

                    }
                }
            });
            return allValResult;
        } else {
            let allValResult: { [x: string]: string } = {};

            if (currentDtl.fromDate && currentDtl.toDate
                && currentDtl.individualChargeQtyUom !== 'DAY' && currentDtl.individualChargeQtyUom !== 'HOUR') {
                allValResult = {
                    mandatoryCheckFail: 'Invalid UOM. The UOM for Storage charge should be DAY or HOUR.'
                };
                return allValResult;
            }
            if (details.some(item =>
                `${item.chargeOnCompany}_${item.tariffType}_${item.tariffCode}_${item.rate}_${item.fromDate}_${item.toDate}`
                ===
                `${currentDtl.chargeOnCompany}_${currentDtl.tariffType}_${currentDtl.tariffCode}_${currentDtl.rate}_${currentDtl.fromDate}_${currentDtl.toDate}` &&
                ((item.id !== null && item.id !== currentDtl.id)
                    || (item.id === null && item.dummyKey !== currentDtl.dummyKey))
            )) {
                allValResult = {
                    mandatoryCheckFail: 'Duplicate record found, please check the key: Charge-on Company, Tar Type, Tar Code, One-off Rate, Charging From Date, Charging To Date.'
                };
                return allValResult;
            }
            manChgDtlDispatch(prevState => {
                const oldDtls = prevState.manualChargeDetails;
                let newDtls = oldDtls?.filter(dtl => (dtl.id !== null && dtl.id !== currentDtl.id)
                    || (dtl.id === null && dtl.dummyKey !== currentDtl.dummyKey));
                newDtls.push(currentDtl);

                return {
                    ...prevState,
                    manualChargeDetails: newDtls,
                    initManChargeDtlCntrs: [],
                    manualChargeDetailCntrs: [],
                    cntrNoStr: null,
                    dtlTotalQty: null,
                    viewState: {
                        ...prevState.viewState,
                        isAdd: false,
                        isRead: false,
                        isEditable: true,
                        isSaveClicked: false,
                        currentSelectedData: EMPTY_MANUAL_CHARGE_DETAIL_ENTITY,
                        allowAutoRefresh: false,
                        isShowDetailInfo: false,
                        allFormState: {}
                    }
                }
            })

            return currentDtl;
        }
    }
    const onApply = async (manChgHdr: ManualChargeHeaderEntity, manChgDtls: ManualChargeDetailEntity[]) => {
        await manualChargeDetailRepo.applyManualChargeDetail({
            chargeCategory: 'MC',
            manChgHdr,
            manChgDtls,
        }).then((res) => {

        })
    }

    const onDelete = async (selectedRows: ManualChargeDetailEntity[]) => {

        await manualChargeDetailRepo.deleteManualChargeDetails(
            selectedRows?.map(row => row.id) as number[]
        ).then((res) => {

        })
    }

    const onAdjustment = async (selectedRows: ManualChargeDetailEntity[], adjEntity: ManualChargeDetailAdjustmentRequestEntity) => {
        if (!selectedRows || selectedRows.length <= 0) return;
        const newAdjEntity: ManualChargeDetailAdjustmentRequestEntity = {
            ...adjEntity,
            manualDtlIds: (selectedRows?.map(row => row.id) as number[])
        }

        return await manualChargeDetailRepo.adjustmentManualChargeDetails(newAdjEntity).then((data) => {
            if (data && data.toString().startsWith("Error:")) {
                manChgDtlDispatch(prevState => {
                    return {
                        ...prevState,
                        allFormState: { "adjustmentManualChargeFail": data.toString() }
                    };
                });
                return { "adjustmentManualChargeFail": data.toString() };
            } else {
                manChgDtlDispatch(prevState => {
                    return {
                        ...prevState,
                        allFormState: { "adjustmentManualChargeSuccess": "successful" },
                        adjustmentEntity: { ...EMPTY_MANUAL_CHARGE_DETAIL_ADJUSTMENT_REQUEST_ENTITY },
                        viewState: {
                            ...prevState.viewState,
                            isShowDetailInfo: false,
                        }
                    };
                });
                return { "adjustmentManualChargeSuccess": "successful" };
            }
        }).catch(error => {
            manChgDtlDispatch(prevState => {
                return {
                    ...prevState,
                    allFormState: { "adjustmentManualChargeFail": error.message }
                }
            });
            return { "adjustmentManualChargeFail": error.message };
        })
    }

    const handleAdjustmentButtonClick = () => {
        manChgDtlDispatch(prevState => {
            return {
                ...prevState,
                isShowAdjustmentPanel: !prevState.isShowAdjustmentPanel,
                adjustmentEntity: { ...EMPTY_MANUAL_CHARGE_DETAIL_ADJUSTMENT_REQUEST_ENTITY }
            }
        });
    }

    const onValidationBeforeAdjustment = async (adjEntity: ManualChargeDetailAdjustmentRequestEntity) => {
        let result: boolean | null | undefined = undefined;

        result = await Validation(updateAdjustmentValidationSchema).ValidateFormOnly(
            adjEntity
        ).then(res => {
            if (res) {
                manChgDtlDispatch(prevState => {
                    return {
                        ...prevState,
                        allFormState: res
                    }
                })
                return false;
            } else {
                return true;
            }
        }).catch(error => {
            return false;
        });
        return result;
    }

    const onViewAttaOpen = () => {
        manChgDtlDispatch(prevState => {
            return {
                ...prevState,
                viewState: {
                    ...prevState.viewState,
                    isShowAttachmentModal: true,
                    attachmentMsg: null,
                }
            }
        })
    }

    const onViewAttaClose = () => {
        manChgDtlDispatch(prevState => {
            return {
                ...prevState,
                viewState: {
                    ...prevState.viewState,
                    isShowAttachmentModal: false,
                    attachmentMsg: null,
                }
            }
        })
    }

    const onAttaModalInit = async (manChgDtl: ManualChargeDetailEntity) => {
        manChgDtl.id && await manualChargeDetailRepo.getManualChargeDetailAttachmentMessage(manChgDtl.id).then((res) => {
            if (res && res.success) {
                manChgDtlDispatch(prevState => {
                    return {
                        ...prevState,
                        viewState: {
                            ...prevState.viewState,
                            attachmentMsg: res.data,
                        }
                    }
                })
            }
        })
    }


    const onHeaderFieldChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }
        return manChgDtlDispatch(prevState => {
            var regex;
            if (fieldKey === 'loa') {
                regex = /^\d*(\.\d{0,2})?$/;
                if (!regex.test(val)) {
                    val = prevState.viewState.editingHeader[fieldKey];
                }
            }

            if (fieldKey === 'poNo') {
                val = (val as string).toUpperCase().replace(/\s+/g, '');
            } else if (fieldKey === 'soa' || fieldKey === 'slVesselCode' || fieldKey === 'slIbVoyageCode' || fieldKey === 'slObVoyageCode') {
                val = (val as string).toUpperCase();
            }
            return {
                ...prevState,
                viewState: {
                    ...prevState.viewState,
                    allFormState: {
                        ...prevState.viewState.allFormState,
                        [fieldKey]: ''
                    },
                    editingHeader: {
                        ...prevState.viewState.editingHeader,
                        [fieldKey]: val,
                    }
                },
            }
        })
    }


    const onFieldChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        let tarCode: any;
        let vatTarCode: any;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }


        return manChgDtlDispatch(prevState => {
            var regex = null;
            if (manChgDtl2dpNumberFieldList.find(e => e === fieldKey)) {
                regex = /^\d*(\.\d{0,2})?$/;
                if (!regex.test(val)) {
                    val = prevState.viewState.currentSelectedData[fieldKey];
                }
            } else if (manChgDtl4dpNumberFieldList.find(e => e === fieldKey)) {
                regex = /^\d*(\.\d{0,4})?$/;
                if (!regex.test(val)) {
                    val = prevState.viewState.currentSelectedData[fieldKey];
                }
            } else if (manChgDtlPositiveIntegerNumberFieldList.find(e => e === fieldKey)) {
                var posIntReg = /^\d*$/;
                if (!posIntReg.test(val)) {
                    val = prevState.viewState.currentSelectedData[fieldKey];
                }
            }
            if (fieldKey === 'tariffType') {
                tarCode = prevState.dynamicOptions.tariffCodeDropdownOptions[val] ? prevState.dynamicOptions.tariffCodeDropdownOptions[val][0].value : "";
            }
            if (fieldKey === 'vatTarType') {
                vatTarCode = prevState.dynamicOptions.taxTariffCodeDropdownOptions[val] ? prevState.dynamicOptions.taxTariffCodeDropdownOptions[val][0].value : "";
            }
            if (fieldKey === 'tariffCode') {
                tarCode = val;
            }
            if (fieldKey === 'vatTarCode') {
                vatTarCode = val;
            }

            return {
                ...prevState,
                viewState: {
                    ...prevState.viewState,
                    currentSelectedData: {
                        ...prevState.viewState.currentSelectedData,
                        [fieldKey]: val,
                        tariffCode: tarCode && tarCode !== null ? tarCode : prevState.viewState.currentSelectedData.tariffCode,
                        vatTarCode: vatTarCode && vatTarCode !== null ? vatTarCode : prevState.viewState.currentSelectedData.vatTarCode,
                    },
                    allFormState: {
                        ...prevState.viewState.allFormState,
                        [fieldKey]: ''
                    },
                }
            }
        });
    }


    const onCntrTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>, fieldKey: string) => {
        let val: string = e.target.value.toUpperCase();
        let cntrCount: number;
        if (val) {
            cntrCount = val.toString().split(',')?.filter(cntr => cntr !== '').length;
        }
        return manChgDtlDispatch(prevState => {
            return {
                ...prevState,
                //cntrNoStr: val.toUpperCase(),
                viewState: {
                    ...prevState.viewState,
                    currentSelectedData: {
                        ...prevState.viewState.currentSelectedData,
                        cntrList: val.toUpperCase(),
                        chargeQty: cntrCount,
                    }
                }
            }
        });
    }

    const onCoVslVoyChange = (inputData: { co?: string, vsl?: string, voy?: string }, fieldName: { co: string, vsl: string, voy: string }) => {
        manChgDtlDispatch(prevState => ({
            ...prevState,
            // currentManualChargeHeader: {
            //     ...prevState.currentManualChargeHeader,
            //     [fieldName.co]: inputData?.co,
            //     [fieldName.vsl]: inputData?.vsl,
            //     [fieldName.voy]: inputData?.voy,
            // },
            viewState: {
                ...prevState.viewState,
                editingHeader: {
                    ...prevState.viewState.editingHeader,
                    [fieldName.co]: inputData?.co?.toUpperCase(),
                    [fieldName.vsl]: inputData?.vsl?.toUpperCase(),
                    [fieldName.voy]: inputData?.voy?.toUpperCase(),
                }
            }
        }))
    }

    const onGetShift = async (opsDate: Date) => {
        return await shiftRepo.getShiftCodeMappingByOpsDate({
            opsDate: opsDate
        }).then((data) => {
            let tempShiftCode: string = '';
            if (data) {
                tempShiftCode = data.shiftCode;
            }
            manChgDtlDispatch(prevState => ({
                ...prevState,
                viewState: {
                    ...prevState.viewState,
                    currentSelectedData: {
                        ...prevState.viewState.currentSelectedData,
                        shiftCode: tempShiftCode,
                    }
                }
            }))

            return tempShiftCode;
        })
    }

    const onGetUom = async (companyCode: string | null, opsDate: Date, tariffType: string, tariffCode: string,
        fromDate: Date, toDate: Date, operatingTml: string | null) => {
        return await tariffProposalRepo.searchDefaultQtyUomByTariff({
            companyCode: companyCode,
            opsDate: opsDate,
            tariffType: tariffType,
            tariffCode: tariffCode,
            fromDate: fromDate,
            toDate: toDate,
            operatingTml: operatingTml,
        }).then((data) => {
            if (data && data.toString().startsWith("Error:")) {
                manChgDtlDispatch(prevState => {
                    return {
                        ...prevState,
                        allFormState: { "getUOMFail": data.toString() }
                    };
                });
                return { "getUOMFail": data.toString() };
            } else {
                const uom = data;
                if (uomDropdownOption.find(u => u.value === uom)) {
                    manChgDtlDispatch(prevState => ({
                        ...prevState,
                        viewState: {
                            ...prevState.viewState,
                            currentSelectedData: {
                                ...prevState.viewState.currentSelectedData,
                                individualChargeQtyUom: uom,
                            }
                        }
                    }))
                    return { "getUOMSuccess": "successful" };
                }
            }
        }).catch(error => {
            manChgDtlDispatch(prevState => {
                return {
                    ...prevState,
                    allFormState: { "getUOMFail": error.message }
                }
            });
            return { "getUOMFail": error.message };
        });
    }

    const onRefreshDefaultTarCode = (taxCode: string | null, tariffTypeDropdownOptions: DropdownProps[], tariffCodeDropdownOptions: DropdownProps[],
        articleStatementCodeDropdownOptions: DropdownProps[]) => {
        taxCode && parameterDetailRepo.getParameterThirdDtlByParaCodeAndParaDtlCode(ParameterCodeValue.TAX_CODE,
            taxCode).then((data) => {

                const vatTarType = getTaxDetail(data, ParameterDetailCodeValue.TAX_TAR_TYPE, tariffTypeDropdownOptions);
                const vatTarCode = getTaxDetail(data, ParameterDetailCodeValue.TAX_TAR_CODE, tariffCodeDropdownOptions);
                const vatArtStCode = getTaxDetail(data, ParameterDetailCodeValue.ARTICLE_STATEMENT, articleStatementCodeDropdownOptions);
                const taxPayTerm = getTaxDetail(data, ParameterDetailCodeValue.TAX_PAYMENT_TERM, null);
                const taxPayRefDate = getTaxDetail(data, ParameterDetailCodeValue.TAX_PAYMENT_REFERENCE_DATE, null);
                const taxPercentage = getTaxDetail(data, ParameterDetailCodeValue.TAX_PERCENTAGE, null);
                manChgDtlDispatch(prevState => ({
                    ...prevState,
                    viewState: {
                        ...prevState.viewState,
                        currentSelectedData: {
                            ...prevState.viewState.currentSelectedData,
                            vatTarType: vatTarType ?? '',
                            vatTarCode: vatTarCode ?? '',
                            vatArticleStatementCode: vatArtStCode ?? '',
                            vatDays: taxPayTerm ? Number(taxPayTerm) : null,
                            vatDaysCredit: taxPayTerm ? Number(taxPayTerm) : null,
                            vatDaysDebit: taxPayTerm ? Number(taxPayTerm) : null,
                            paymentReferenceDate: taxPayRefDate ?? '',
                            paymentReferenceDateCredit: taxPayRefDate ?? '',
                            paymentReferenceDateDebit: taxPayRefDate ?? '',
                            vatPercentage: taxPercentage ? Number(taxPercentage) : null,
                        }
                    }
                }))

                return vatArtStCode;
            }).then((vatArtStCode) => {
                vatArtStCode && parameterDetailRepo.getParameterSecondDtlByParaCodeAndParaDtlCode(ParameterCodeValue.ARTICLE_STMT_CODE,
                    vatArtStCode).then((data) => {

                        manChgDtlDispatch(prevState => ({
                            ...prevState,
                            viewState: {
                                ...prevState.viewState,
                                currentSelectedData: {
                                    ...prevState.viewState.currentSelectedData,
                                    vatArticleStatement: data?.parameterDtlDesc ?? null,
                                }
                            }
                        }))

                    })
            })

        taxCode && parameterDetailRepo.getParameterSecondDtlByParaCodeAndParaDtlCode(ParameterCodeValue.TAX_CODE,
            taxCode).then((data) => {

                manChgDtlDispatch(prevState => ({
                    ...prevState,
                    viewState: {
                        ...prevState.viewState,
                        currentSelectedData: {
                            ...prevState.viewState.currentSelectedData,
                            vatTransactionType: data?.parameterDtlDesc ?? null,
                        }
                    }
                }))

            })


    }

    const getTaxDetail = (data: ParameterDetailEntity[], codeValue: string, dropdownOptions: DropdownProps[] | null) => {
        const dtl = data.find(dtl => dtl.parameterDtlCode === codeValue);
        if (dtl?.activeInd === 'Y') {
            if (dropdownOptions === null || (dropdownOptions && dropdownOptions.find(t => dtl.parameterDtlDesc === t.value))) {
                return dtl.parameterDtlDesc;
            }
        }
        return '';
    }

    const onTariffCodeItemChanged = (tariffType: string | null, tariffCode: string | null, fieldName: string) => {
        if (tariffType && tariffCode) {
            standardTariffCodeRepo.getTariffByTypeAndCode(tariffType, tariffCode).then((data) => {
                if (data) {
                    updateCurrentSelectedData(fieldName, data.tariffCodeDesc);
                } else {
                    updateCurrentSelectedData(fieldName, null);
                }
            }).catch(() => {
                updateCurrentSelectedData(fieldName, null);
            })
        } else {
            updateCurrentSelectedData(fieldName, null);
        }
    }

    const updateCurrentSelectedData = (fieldName: string, newValue: any) => {
        manChgDtlDispatch((prevState) => ({
            ...prevState,
            viewState: {
                ...prevState.viewState,
                currentSelectedData: {
                    ...prevState.viewState.currentSelectedData,
                    [fieldName]: newValue,
                },
            },
        }));
    }

    const onDropdownChange = (e: any, fieldName: string, obj: any, objName: string) => {
        obj = {
            ...obj,
            [fieldName]: e?.value ?? (_.isEmpty(e) ?
                (fieldName === "subChgTypeList" ? [] : '') : e)
        };
        manChgDtlDispatch(prevState => ({
            ...prevState,
            [objName]: obj
        }))
    }

    const uploadCntr = async (cntrNoStr: string, file: any) => {
        if (file) {
            const arrayBuffer = await readFileAsArrayBuffer(file);
            const cntrNoArray = await processFileData(arrayBuffer, 'CNTR_NO');

            const sortedList = [...cntrNoArray]?.sort();
            let sortCntrStr = "";
            // eslint-disable-next-line array-callback-return
            sortedList?.map((item, index) => {
                sortCntrStr = sortCntrStr + item;

                if (index !== (sortedList.length - 1)
                    && !sortCntrStr.endsWith(",")) {
                    sortCntrStr = sortCntrStr + ",";
                }
            });

            let tempCntrStr = cntrNoStr;
            if (tempCntrStr && !tempCntrStr.endsWith(",")) {
                tempCntrStr = cntrNoStr + ",";
            }

            const tempCntrNoStr = tempCntrStr + sortCntrStr;
            const tempChargeQty = tempCntrNoStr ? tempCntrNoStr.split(',').length : null;
            return manChgDtlDispatch(prevState => {
                return {
                    ...prevState,
                    cntrNoStr: tempCntrNoStr.toUpperCase(),
                    dtlTotalQty: tempChargeQty,
                    viewState: {
                        ...prevState.viewState,
                        currentSelectedData: {
                            ...prevState.viewState.currentSelectedData,
                            cntrList: tempCntrNoStr.toUpperCase(),
                            chargeQty: tempChargeQty
                        }
                    }
                }
            });
        }
    };

    const uploadAttachment = async (manualChgDtl: ManualChargeDetailEntity, file: any) => {
        if (file) {
            const arrayBuffer = await readFileAsArrayBuffer(file);
            const attachArray = await processFileData(arrayBuffer, 'ATTACHMENT');

            if (attachArray) {
                manualChgDtl.attachs = attachArray;
            }
        }

        let retMsg;
        await manualChargeDetailRepo.uploadAttachment(manualChgDtl).then((data) => {
            if (data && data.toString().startsWith("Error:")) {
                retMsg = { "uploadAttachmentFail": data.toString() };
            } else {
                retMsg = { "uploadAttachmentSucess": data.toString() };
            }
        }).catch(error => {
            retMsg = { "applyManualChargeCntrFail": error.message };
        })

        return retMsg;
    };

    async function readFileAsArrayBuffer(file: any) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (e) => resolve(e.target?.result);
            reader.onerror = (e) => reject(e.target?.error);

            reader.readAsArrayBuffer(file);
        });
    }

    async function processFileData(arrayBuffer: any, datayType: string) {
        const byteArray = new Uint8Array(arrayBuffer);
        const decoder = new TextDecoder('ascii');
        const content = decoder.decode(byteArray);
        const lines = content.split('\n')?.map(line => line.trim());

        let attachArray = lines?.map(line => line.trim().substring(0, 400));
        if (datayType === 'CNTR_NO') {
            attachArray = lines?.map(line => line.split(',')[0].trim()); // Split each line by comma
        } else if (datayType === 'ATTACHMENT') {
            attachArray = lines?.map(line => line.trim().substring(0, 400));
        }

        return attachArray;
    }

    return {
        onPageInit: onPageInit,
        //onHeaderClose: onHeaderClose,
        onCloseScreen: onCloseScreen,
        onShowLoading: onShowLoading,
        onHideLoading: onHideLoading,
        updateSelectedManualChargeDetails: updateSelectedManualChargeDetails,
        onSelectedSingleChargeDetail: onSelectedSingleChargeDetail,
        updateCheckboxRef: updateCheckboxRef,
        onResetCheckboxValue: onResetCheckboxValue,
        onSearch,
        loadDropdownOption: loadDropdownOption,
        onAdd: onAdd,
        onEdit: onEdit,
        onCancel: onCancel,
        onCancelDetail: onCancelDetail,
        onSaveClicked: onSaveClicked,
        onRowClick: onRowClick,
        onSave: onSave,
        onSaveDetail: onSaveDetail,
        onApply: onApply,
        onDelete: onDelete,
        onViewAttaOpen: onViewAttaOpen,
        onViewAttaClose: onViewAttaClose,
        onAttaModalInit: onAttaModalInit,
        onHeaderFieldChange: onHeaderFieldChange,
        onFieldChange: onFieldChange,
        onCoVslVoyChange: onCoVslVoyChange,
        onGetShift: onGetShift,
        onRefreshDefaultTarCode: onRefreshDefaultTarCode,
        onGetUom: onGetUom,
        onTariffCodeItemChanged: onTariffCodeItemChanged,
        onCntrTextAreaChange: onCntrTextAreaChange,
        onInitDefaultValue: onInitDefaultValue,
        onAdjustment: onAdjustment,
        handleAdjustmentButtonClick: handleAdjustmentButtonClick,
        onDropdownChange: onDropdownChange,
        onValidationBeforeAdjustment: onValidationBeforeAdjustment,
        uploadAttachment: uploadAttachment,
        uploadCntr: uploadCntr
    }
}