import { ChargeTypeRepoImpl } from "domain/repository/ChargeType/ChargeTypeRepoImpl";
import { SearchPreferenceRepoImpl } from "domain/repository/Common/SearchPreferenceRepoImpl";
import { ShiftRepoImpl } from "domain/repository/Common/ShiftRepoImpl";
import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { ManualChargeDetailRepoImpl } from "domain/repository/ManualCharge/ManualChargeDetailRepoImpl";
import { ManualChargeHeaderRepoImpl } from "domain/repository/ManualCharge/ManualChargeHeaderRepoImpl";
import { MasterDataRepoImpl } from "domain/repository/MasterData/MasterDataRepoImpl";
import { StandardTariffCodeRepoImpl } from "domain/repository/TariffCode/StandardTariffCodeRepoImpl";
import { TariffProposalRepoImpl } from "domain/repository/TariffCode/TariffProposalRepoImpl";
import { TariffTypeRepoImpl } from "domain/repository/TariffCode/TariffTypeRepoImpl";
import { ParameterDetailRepoImpl } from "domain/repository/parameter/ParameterDetailRepoImpl";
import { ParameterRepoImpl } from "domain/repository/parameter/ParameterRepoImpl";
import { useManualChargeDetailTracked } from "presentation/store/ManualCharge/ManualChargeDetailProvider";
import { useManualChargeHeaderMaintenanceTracked } from "presentation/store/ManualCharge/ManualChargeHeaderMaintenanceProvider";
import { ManualChargeDetailVM } from "presentation/viewModel/ManualCharge/ManualChargeDetailVM";
import { useMemo } from "react";

export const useManualChargeDetailVM = () => {
    const [, setManualChargeDetailState] = useManualChargeDetailTracked();
    const [, setManualChargeHeaderMaintenanceState] = useManualChargeHeaderMaintenanceTracked();
    const memoManualChargeDetailVM = useMemo(() =>
        ManualChargeDetailVM({
            dispatch: [setManualChargeDetailState],
            hdrDispatch: [setManualChargeHeaderMaintenanceState],
            chargeTypeRepo: ChargeTypeRepoImpl(),
            companyRepo: CompanyRepoImpl(),
            searchPreferenceRepo: SearchPreferenceRepoImpl(),
            manualChargeHeaderRepo: ManualChargeHeaderRepoImpl(),
            manualChargeDetailRepo: ManualChargeDetailRepoImpl(),
            masterDataRepo: MasterDataRepoImpl(),
            standardTariffCodeRepo: StandardTariffCodeRepoImpl(),
            tariffTypeRepo: TariffTypeRepoImpl(),
            parameterDetailRepo: ParameterDetailRepoImpl(),
            shiftRepo: ShiftRepoImpl(),
            tariffProposalRepo: TariffProposalRepoImpl(),
            parameterRepo: ParameterRepoImpl(),
        }), [setManualChargeDetailState, setManualChargeHeaderMaintenanceState])

    return memoManualChargeDetailVM
}